@import '../../styles/customMediaQueries.css';

.root {
  /* Layout */
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;

  /* Remove link's hover effect */
  &:hover {
    text-decoration: none;
    transition: all ease 0.5s;
    box-shadow: var(--boxShadowListingCard);

    & .aspectRatioWrapper {
      transition: all ease 0.5s;

      @media (--viewportMedium) {
        & img {
          transform: scale(1.2);
          transition: all ease 0.5s;
        }
      }
    }
  }

  & .aspectRatioWrapper {
    transition: all ease 0.5s;
    background: var(--matterColorNegative);
    height: auto !important;
    width: 100%;
    border-radius: 10px 10px 0 0;
    position: relative;
    overflow: hidden;

    & img {
      transform: scale(1);
      transition: all ease 0.5s;
    }

    /* &>div {
      padding: 0px !important;
  
      &>div {
        position: relative;
  
        &>div {
          position: relative !important;
        }
      }
    } */
  }
}

.aspectRatioWrapper {
  transition: all ease 0.5s;
  background: var(--matterColorNegative);
  height: auto !important;
  width: 100%;
  border-radius: 10px 10px 0 0;
  position: relative;
  overflow: hidden;
}

.soldCard {
  position: relative;
}

.favorite {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: var(--matterColorLight);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 16px #00000045;

  & svg {
    color: #4a4a4a;
    width: 20px;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.saves {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: var(--matterColorLight);
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 16px #00000045;

  &>span {
    &>img {
      width: 24px;
    }
  }

  & svg {
    margin-top: -2px;
    margin-left: 0px;
    color: #4a4a4a;
    width: 20px;

    &:hover {
      color: var(--marketplaceColor);
    }
  }
}

.rootForImage {
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.info {
  display: flex;
  flex-direction: column;
  padding: 10px 12px;
  border-top: solid 1px #ddd;
}

.price {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-shrink: 0;
  margin-right: 18px;
}

.priceValue {
  composes: marketplaceH3FontStyles from global;
  margin-top: 0;
  margin-bottom: 0;
}

.perUnit {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  display: inline-flex;
  align-items: center;
  margin-left: 8px;
  margin-top: 0;
  margin-bottom: 0;
}

.mainInfo {
  display: flex;
  flex-direction: column;
}

.title {
  composes: marketplaceDefaultFontStyles from global;
  line-height: 24px;
  font-size: 14px;
  color: var(--matterColor);
  margin-top: 0;
  margin-bottom: 0;
}

.authorInfo {
  composes: marketplaceTinyFontStyles from global;
  color: var(--matterColor);
  padding-top: 4px;
  padding-bottom: 4px;
  margin-top: 0;
  margin-bottom: 0;
}

.longWord {
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}

.filled {
  fill: var(--marketplaceColor);

  &>path {
    fill: var(--marketplaceColor);
    stroke: none;
  }
}

.firstPhoto {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  &>img {
    object-fit: contain;
    max-width: 100%;
    /* width: 296px;
    height: 334px;
    object-fit: cover;

    @media only screen and (min-width: 300px) and (max-width: 375px) {
      width: 273px;
      height: 343px;
      object-fit: cover;
    }

    @media only screen and (min-width: 375px) and (max-width: 425px) {
      width: 327px;
      height: 380px;
      object-fit: cover;
    }

    @media only screen and (min-width: 425px) and (max-width: 768px) {
      width: 383px;
      height: 383px;
      object-fit: cover;
    }

    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      width: 235px;
      height: 295px;
      object-fit: cover;
    }

    @media only screen and (min-width: 1024px) and (max-width: 1440px) {
      width: 211px;
      height: 283px;
      object-fit: cover;
    }

    @media (min-width:1440px) {
      width: 293px;
      height: 343px;
      object-fit: cover;
    } */
  }
}

.firstPhotoProfile {
  height: 100%;
  width: 100%;

  &>img {
    width: 100%;
    height: 100%;
    max-width: 100%;
    object-fit: cover;
  }
}

.soldStock {
  position: relative;
  background: #c6488c;
  padding: 4px 10px;
  border-radius: 14px;
  font-size: 12px;
  left: 4px;
  color: white;
  top: 4px;
}